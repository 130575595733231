// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import "bootstrap"
import "../stylesheets/application"

import "@fortawesome/fontawesome-free/js/all"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function addProductionSearchRow(user) {
	var members = $('.removefromproduction');
	var i = 0;
	while (i < members.length) {
		if ($(members[i]).data('id') == user.id)
			return;
		i++;
	}
	var tr = document.createElement('tr');
	var td = document.createElement('td');
	td.textContent = user.name;
	tr.appendChild(td);
	td = document.createElement('td');
	td.textContent = user.userid;
	tr.appendChild(td);
	td = document.createElement('td');
	// td.textContent = user.producer ? 'Yes' : '-';
	var button = document.createElement('button');
	button.setAttribute('type', 'submit');
	button.setAttribute('class', 'addproducertoproduction');
	button.setAttribute('data-id', user.id);
	button.textContent = 'Add Producer';
	td.appendChild(button);
	// instead of the zone select below, a hidden field for the fixed zone
	var hidden = document.createElement('input');
	hidden.setAttribute('type', 'hidden');
	hidden.setAttribute('id', 'addzone-' + user.id);
	hidden.setAttribute('value', '1');
	td.appendChild(hidden);
	tr.appendChild(td);

	/* no zone information being displayed, though the system still supports it:
        td = document.createElement('td');

	var select = document.createElement('select');
	select.setAttribute('id', 'addzone-' + user.id);

	var option = document.createElement('option');
	option.setAttribute('value', 1);
	option.textContent = 'A';
	select.appendChild(option);

	option = document.createElement('option');
	option.setAttribute('value', 3);
	option.textContent = 'B';
	select.appendChild(option);
	*/
	
	/*
	i = 1;
	while (i <= 3) {
		var option = document.createElement('option');
		option.setAttribute('value', i);
		var numdays = (i - 1) * 2;
		numdays += 3;
		option.textContent = i + ' (' + numdays + ' days)';
		select.appendChild(option);
		i = i + 1;
	}
	*/
	/*
	td.appendChild(select);
	tr.appendChild(td);
	*/
	td = document.createElement('td');
	button = document.createElement('button');
	button.setAttribute('type', 'submit');
	button.setAttribute('class', 'addtoproduction');
	button.setAttribute('data-id', user.id);
	button.textContent = 'Add Performer or Crew';
	td.appendChild(button);
	tr.appendChild(td);
	document.getElementById('searchresultrows').appendChild(tr);
}

function addClearanceRow(user) {
	var tr = document.createElement('tr');

	var td = document.createElement('td');
	td.textContent = user.name;
	tr.appendChild(td);

	td = document.createElement('td');
	td.textContent = user.userid;
	tr.appendChild(td);

	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	var span = document.createElement('span');
	if (user.passpanel_clear === true) {
		span.setAttribute('class', 'fa fa-2x fa-check');
		span.setAttribute('style', 'color: green;');
	} else {
		span.setAttribute('class', 'fa fa-2x fa-times');
		span.setAttribute('style', 'color: red;');
	}
	td.appendChild(span);
	tr.appendChild(td);

	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	td.textContent = user.passpanel_available;
	tr.appendChild(td);

	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	if (user.covid_vaxxed === true) {
		span = document.createElement('span');
		span.setAttribute('class', 'fa fa-2x fa-check');
		span.setAttribute('style', 'color: green;');
		td.appendChild(span);
	} else {
		td.textContent = '-';
	}
	tr.appendChild(td);

	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	td.textContent = user.covid_days_since;
	tr.appendChild(td);

	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	if (user.mgen_clear === true) {
		span = document.createElement('span');
		span.setAttribute('class', 'fa fa-2x fa-check');
		span.setAttribute('style', 'color: green;');
		td.appendChild(span);
	} else {
		td.textContent = '-';
	}
	tr.appendChild(td);

    td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	if (document.roster) {
		var button = document.createElement('button');
		button.setAttribute('class', 'addroster');
		button.setAttribute('type', 'submit');
		button.setAttribute('data-roster-id', user.id);
		button.textContent = 'Add to Roster';
		td.appendChild(button);
	}
	tr.appendChild(td);

	// blank column
	td = document.createElement('td');
	td.setAttribute('class', 'text-center');
	tr.appendChild(td);
	
	document.getElementById('dbsearchrows').appendChild(tr);
}

function appendSelectOptions(select, prefix) {
	var option = document.createElement('option');
	option.setAttribute('value', 'n/a');
	option.textContent = 'Not Tested';
	select.appendChild(option);

	option = document.createElement('option');
	option.setAttribute('value', true);
	option.textContent = 'Clear';
	select.appendChild(option);

	option = document.createElement('option');
	option.setAttribute('value', false);
	option.textContent = 'Not Clear';
	select.appendChild(option);
}

function addTestSearchRow(user) {
	var tr = document.createElement('tr');
	var td = document.createElement('td');
	td.textContent = user.name;
	tr.appendChild(td);
	td = document.createElement('td');
	td.textContent = user.userid;
	tr.appendChild(td);
	td = document.createElement('td');
	td.textContent = user.date_of_birth;
	tr.appendChild(td);
	td = document.createElement('td');
	var input = document.createElement('input');
	input.setAttribute('type', 'date');
	input.setAttribute('name', 'testedon-' + user.id);
	input.setAttribute('id', 'testedon-' + user.id);
	input.setAttribute('value', user.today);
	input.setAttribute('max', user.today);
	td.appendChild(input);
	tr.appendChild(td);

	const clinicSelect = document.getElementById('clinicresults');
	if (clinicSelect?.options.length > 0) {
		td = document.createElement('td');
		var select = document.createElement('select');
		select.setAttribute('id', 'clinicresult-' + user.id);
		select.setAttribute('class', 'clinicresult');
		select.setAttribute('data-id', user.id);
		// copy the global #clinicresults select options to the right place for each entry, then remove the global select
		[].forEach.call(clinicSelect.options, thisOption => {
			let option = document.createElement('option');
			option.setAttribute('value', thisOption.value);
			option.textContent = thisOption.text;
			select.appendChild(option);
		});
		td.appendChild(select);
		tr.appendChild(td);
	}

	td = document.createElement('td');
	var select = document.createElement('select');
	select.setAttribute('id', 'passpanelresult-' + user.id);
	select.setAttribute('class', 'passpanelresult');
	select.setAttribute('data-id', user.id);
	appendSelectOptions(select, 'PASS');
	td.appendChild(select);
	tr.appendChild(td);

	td = document.createElement('td');
	select = document.createElement('select');
	select.setAttribute('id', 'covidresult-' + user.id);
	select.setAttribute('class', 'covidresult');
	select.setAttribute('data-id', user.id);
	appendSelectOptions(select, 'COVID-19');
	td.appendChild(select);
	tr.appendChild(td);

	td = document.createElement('td');
	select = document.createElement('select');
	select.setAttribute('id', 'mgenresult-' + user.id);
	select.setAttribute('class', 'mgenresult');
	select.setAttribute('data-id', user.id);
	appendSelectOptions(select, 'MGEN');
	td.appendChild(select);
	tr.appendChild(td);

	td = document.createElement('td');
	var button = document.createElement('button');
	button.setAttribute('type', 'submit');
	button.setAttribute('class', 'bothsubmit');
	button.setAttribute('data-id', user.id);
	button.textContent = 'Submit';
	td.appendChild(button);
	tr.appendChild(td);

	document.getElementById('searchresultrows').appendChild(tr);
}

function hookActionButtons() {
	$('.addtoproduction').on('click', function(e) {
                var uid = $(e.target).data('id');
		$('#addproducer').val(0);
		$('#adduser').val(uid);
                $('#adduserzone').val($('#addzone-' + uid).val());
		$('#adduserform').submit();
	});
	$('.addproducertoproduction').on('click', function(e) {
                var uid = $(e.target).data('id');
		$('#addproducer').val(1);
		$('#adduser').val(uid);
                $('#adduserzone').val($('#addzone-' + uid).val());
		$('#adduserform').submit();
	});
	$('.removefromproduction').on('click', function(e) {
		$('#removeuser').val($(e.target).data('id'));
		$('#adduserform').submit();
	});
	$('.covidcleartestresult').on('click', function(e) {
		var b = $(e.target).closest('.covidcleartestresult');
		var id = b.data('id');
		$('#addtest').val(id);
		$('#addtestdate').val($('#testedon-' + id).val());
		$('#addtestpassresult').val('n/a');
		$('#addtestcovidresult').val(true);
		$('#addtestform').submit();
	});
	$('.covidnotcleartestresult').on('click', function(e) {
		var b = $(e.target).closest('.covidnotcleartestresult');
		var id = b.data('id');
		$('#addtest').val(id);
		$('#addtestdate').val($('#testedon-' + id).val());
		$('#addtestpassresult').val('n/a');
		$('#addtestcovidresult').val(false);
		$('#addtestform').submit();
	});
	$('.mgencleartestresult').on('click', function(e) {
		var b = $(e.target).closest('.mgencleartestresult');
		var id = b.data('id');
		$('#addtest').val(id);
		$('#addtestdate').val($('#testedon-' + id).val());
		$('#addtestpassresult').val('n/a');
		$('#addtestmgenresult').val(true);
		$('#addtestform').submit();
	});
	$('.mgennotcleartestresult').on('click', function(e) {
		var b = $(e.target).closest('.mgennotcleartestresult');
		var id = b.data('id');
		$('#addtest').val(id);
		$('#addtestdate').val($('#testedon-' + id).val());
		$('#addtestpassresult').val('n/a');
		$('#addtestmgenresult').val(false);
		$('#addtestform').submit();
	});
	$('.bothsubmit').on('click', function(e) {
		var id = $(e.target).data('id');
		var pr = $('#passpanelresult-' + id).val();
		var cr = $('#covidresult-' + id).val();
		var mr = $('#mgenresult-' + id).val();
		const clinicSelect = document.getElementById('clinicresults');
		if (clinicSelect?.options.length > 0) {
			var cl = $('#clinicresult-' + id).val();
		}
		$('#addtest').val(id);
		$('#addtestdate').val($('#testedon-' + id).val());
		$('#addtestpassresult').val(pr);
		$('#addtestcovidresult').val(cr);
		$('#addtestmgenresult').val(mr);
		if (clinicSelect?.options.length > 0) {
			$('#addclinicid').val(cl);
		}
		$('#addtestform').submit();
	});
	$('.addroster').on('click', function(e) {
		var id = $(e.target).data('roster-id');
		$('#addroster_id').val(id);
		$('#addrosterform').submit();
	});
	$('.removeroster').on('click', function(e) {
		var id = $(e.target).data('roster-id');
		$('#removeroster_id').val(id);
		$('#removerosterform').submit();
	});
	$('#copyUUIDButton').on('click', function(e) {
		writeClipboardText(document.getElementById('copyUUID').innerText);
	});
}

async function writeClipboardText(text) {
	try {
		await navigator.clipboard.writeText(text);
	} catch (error) {
		console.error(error.message);
	}
}

function noSearchResults() {
	$('#searchresultrows').empty();
	var tr = document.createElement('tr');
	var td = document.createElement('td');
	td.setAttribute('colspan', 9);
	if (window.location.pathname.indexOf('tests') > 0) {
		var i = document.createElement('i');
		i.textContent = 'No matching database records. If you entered the name correctly, then click ';
		td.appendChild(i);
		var a = document.createElement('a');
		a.setAttribute('href', '/deferred_tests/new');
		i = document.createElement('i');
		i.textContent = 'here to add a deferred test result. ';
		a.appendChild(i);
		td.appendChild(a);
		i = document.createElement('i');
		i.textContent = 'Once the participant agrees to data sharing via eConsent, their account will become active and their availability will be visible.';
		td.appendChild(i);
	} else {
		var i = document.createElement('i');
		i.textContent = 'No matching database records.';
		td.appendChild(i);
	}
	tr.appendChild(td);
	$('#searchresultrows').append(tr);
}

function populateSearchResults(users) {
	$('#searchresults').removeAttr('style');
	if (users.length == 0) {
		noSearchResults();
		return;
	}
	$('#searchresultrows').empty();
	if ($('#addtest').length == 0)
		users.forEach(addProductionSearchRow);
	else
		users.forEach(addTestSearchRow);
	hookActionButtons();
	if ($('#searchresultrows tr').length == 0)
		noSearchResults();
	else
		$('#searchnoresults').attr('style', 'display: none;');
}

function noMatches() {
	$('#dbsearchrows').empty();
	var tr = document.createElement('tr');
	var td = document.createElement('td');
	td.setAttribute('colspan', 9);
	var i = document.createElement('i');
	i.textContent = 'No matching database records.';
	td.appendChild(i);
	tr.appendChild(td);
	$('#dbsearchrows').append(tr);
}

function populateClearances(users) {
	$('#dbsearchresults').removeAttr('style');
	if (users.length == 0) {
		noMatches();
		return;
	}
	$('#dbsearchrows').empty();
	users.forEach(addClearanceRow);
	hookActionButtons();
	if ($('#dbsearchrows tr').length == 0) {
		noMatches();
	} else {
		$('#dbnosearchresults').attr('style', 'display: none;');
	}
}

function candidates() {
	$.ajax({
		url: '/users/search',
		type: 'post',
		dataType: 'json',
		data: {
			firstname: $('#firstname').val(),
			lastname: $('#lastname').val(),
			passid: $('#passid').val()
		},
		success: function(response, status, xhr) {
			if (response.hasOwnProperty('users'))
				populateSearchResults(response.users);
		},
		error: function(x, status, msg) {
			console.log(msg);
		}
	});
}

function clearances() {
	$.ajax({
		url: '/users/search',
		type: 'post',
		dataType: 'json',
		data: {
			firstname: $('#dbfirstname').val(),
			lastname: $('#dblastname').val(),
			passid: $('#dbpassid').val()
		},
		success: function(response, status, xhr) {
			if (response.hasOwnProperty('users'))
				populateClearances(response.users);
		},
		error: function(x, status, msg) {
			console.log(msg);
		}
	});
}

function updateTooltips(with_new) {
	var tooltips = {};
	var locations = {};
	$('.tooltip-location').each(function() {
		var existing_id = $(this).data('tooltip-id');
		var v = $(this).val();
		locations[existing_id] = v;

	});
	$('.tooltip-content').each(function() {
		var existing_id = $(this).data('tooltip-id');
		var v = $(this).val();
		var tooltip_object = {
			id: existing_id,
			location: locations[existing_id],
			content: v
		};
		tooltips[existing_id] = tooltip_object;
	});
	if (with_new) {
		var new_id = $('#new-tooltip-id').val();
		var new_loc = $('#new-tooltip-location').val();
		var new_content = $('#new-tooltip').val();
		var new_tooltip_object = {
			id: new_id,
			location: new_loc,
			content: new_content
		};
		tooltips[new_id] = new_tooltip_object;
	}
	$.ajax({
		url: '/tooltips/update',
		type: 'patch',
		dataType: 'json',
		data: {
			tooltips: tooltips
		},
		success: function(response, status, xhr) {
			if (with_new)
				Turbolinks.visit('/tooltips', { action: 'replace' });
		},
		error: function(x, status, msg) {
			console.log(msg);
		}
	});
}

document.addEventListener('turbolinks:load', function() {
	$('#firstname').on('input', debounce(function() {
		candidates();
	}, 400));
	$('#lastname').on('input', debounce(function() {
		candidates();
	}, 400));
	$('#passid').on('input', debounce(function() {
		candidates();
	}, 400));
	$('#dbfirstname').on('input', debounce(function() {
		clearances();
	}, 400));
	$('#dblastname').on('input', debounce(function() {
		clearances();
	}, 400));
	$('#dbpassid').on('input', debounce(function() {
		clearances();
	}, 400));
	hookActionButtons();
	$('.sortable').on('click', function(e) {
		var dd = $(e.target).closest('.sortable');
		Turbolinks.visit($(dd).data('path'), { action: 'replace' });
	});
	// flatpickr(".datepicker", { defaultDate: "today"})
	flatpickr(".datepicker", {})
	$('.tooltip-location').on('keydown', debounce(function() {
		updateTooltips(false);
	}, 400));
	$('.tooltip-content').on('keydown', debounce(function() {
		updateTooltips(false);
	}, 400));
	$('#new-tooltip-save').on('click', function(e) {
		updateTooltips(true);
	});
	if (document.getElementById('deadscript') != null) {
		$('#deadscript').remove();
	}
});
